<template>
    <div class="memod-tos">
        <div class="legal">
            <div class="container">
                <div class="header">
                    <h1>End-User License Agreement</h1>
                </div>
                <p>This End-User License Agreement (&quot;EULA&quot;) is  a binding contract between you (“you”) and Memo’d Technologies, LLC (“Memo’d,” “we,” “us” or “our”) governing your use of any mobile applications that Memo’d makes available for download or use (individually and collectively, the “App”), the Memo’d website located at <a href="http://www.memod.com">www.memod.com</a>, and any other online properties owned or controlled by or on behalf of Memo’d (collectively with the App, the “Services”). BY ACCESSING, DOWNLOADING, INSTALLING, OR OTHERWISE USING THE SERVICES, YOU AGREE THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND BY THIS EULA. IF YOU DO NOT AGREE TO THE TERMS OF THIS EULA, THEN YOU MUST NOT USE THESE SERVICES.</p>
                <div class="table-of-content">
                    <span>1. <a href="#1">License</a></span>
                    <span>2. <a href="#2">Restrictions</a></span>
                    <span>3. <a href="#3">Modifications to Services</a></span>
                    <span>4. <a href="#4">Additional Terms</a></span>
                    <span>5. <a href="#5">Term and Termination</a></span>
                    <span>6. <a href="#6">Privacy</a></span>
                    <span>7. <a href="#7">Warranty Clause</a></span>
                    <span>8. <a href="#8">Severability</a></span>
                    <span>9. <a href="#9">Amendments to this Agreement</a></span>
                    <span>10. <a href="#10">Contact Information</a></span>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="eula-html" v-html="eula" />
        </div>
    </div>
</template>

<script>
import eula from "@assets/terms/eula.html";

export default {
    name: "MemodTos",
    data: () => ({ eula }),
    mounted() {
        window.scrollTo(0, 0);
    }
}
</script>

<style lang="scss">
.eula-html {
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;

    a {
        color: rgb(5, 168, 143);
    }

    .category-title {
        font-size: 20px;
        line-height: 20px;
        margin-bottom: 35px;
        font-weight: 600;
    }
}
</style>
